import Vue from 'vue'
import Vuex from 'vuex'
import { CHAT_INIT, CHAT_GET_OPENAI_STATUS, CHAT_GET_PERSONA, CHAT_SET_PERSONA, CHAT_GET_SHORTID } from "./action.type"
import { CHAT_BOTSTATUS } from './getter.type'
import * as mutation from './mutation.type'

Vue.use(Vuex)

export default {
  namespaced: true,
  state: {
    openAIStatus: "offline",
    shortId: null,
    persona: null,
  },
  mutations: {
    [mutation.SET_OPENAISTATUS](state, data) {
      state.openAIStatus = data
    },
    [mutation.SET_SHORTID](state, data) {
      state.shortId = data
    },
    [mutation.SET_PERSONA](state, data) {
      state.persona = data
    },
  },
  getters: {
    [CHAT_BOTSTATUS]: (state) => {
      return state.openAIStatus == "online" && state.shortId ? "online" : "storing"
    }
  },
  actions: {
    async [CHAT_INIT](context, { loggedIn }) {
      await context.dispatch(CHAT_GET_SHORTID, { loggedIn })
      if (loggedIn) await context.dispatch(CHAT_GET_PERSONA, { loggedIn })
    },
    async [CHAT_GET_SHORTID](context, { loggedIn }) {
      //TODO: Token still sent
      const response = loggedIn ? await Vue.prototype.$http.get("scan/init", { retry: 3 }) : await Vue.prototype.$http.get("scan/init/anonymous", { load: false, credentials: false, retry: 3 })

      context.commit(mutation.SET_SHORTID, response.data)
    },
    async [CHAT_GET_PERSONA](context) {
      //TODO: Token still sent
      const response = await Vue.prototype.$http.get("chat/persona")

      context.commit(mutation.SET_PERSONA, response.data.persona)
    },
    async [CHAT_SET_PERSONA](context, { seed }) {
      await Vue.prototype.$http.post("chat/persona", { persona: seed })

      context.commit(mutation.SET_PERSONA, seed)
    },
    async [CHAT_GET_OPENAI_STATUS](context) {
      let response = await fetch("https://status.openai.com/api/v2/status.json", {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
      })
      response = await response.json()

      if (response.status.indicator !== "none") {
        context.commit(mutation.SET_OPENAISTATUS, "storing")
      } else {
        context.commit(mutation.SET_OPENAISTATUS, "online")
      }
    }
  }
}
